<template>
 <sdPageHeader title="Zonnepanelen"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #address>
          <sdCards title="Adres selecteren">
           <!--           <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">-->
           <!--            <line :x2="(Math.cos(-45 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(-45 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            <line :x2="(Math.cos(0 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(0 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            &lt;!&ndash; If you do not specify the stroke-->
           <!--                 color the line will not be visible &ndash;&gt;-->
           <!--           </svg>-->
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <div class="create-account-form">
              <a-form
               :model="addressForm"
               :rules="addressFormRules"
               layout="vertical"
               name="account"
               @finish="globalSearch"
              >
               <a-row :gutter="30">
                <a-col :sm="10" :xs="24">
                 <a-form-item label="Postcode" name="postalCode">
                  <a-input v-model:value="addressForm.postalCode" placeholder=""/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item label="Huisnummer" name="houseNumber">
                  <a-input v-model:value="addressForm.houseNumber"
                           onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder="" type="number"/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                              name="houseNumberAddition"
                 >
                  <a-select v-model:value="addressForm.houseNumberAddition" :allowClear="true"
                            class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                    :key="index"
                                    :value="item.value" name="energyAllowSingleFuelElectricity">
                    {{ item.key }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24">
                 <a-form-item label="&nbsp;">
                  <a-button :disabled="disabledGlobalSearchButton||globalSearchLoading||loadingHouseNumberAdditions"
                            :loading="globalSearchLoading||loadingHouseNumberAdditions" class="btn-signin w-100"
                            html-type="submit"
                            size="large"
                            type="primary">
                   Zoeken
                  </a-button>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </a-col>
           </a-row>
          </sdCards>
          <a-row v-if="globalSearchData && globalSearchData.address" :gutter="30">
           <a-col :md="14">
            <sdCards title="Klant selecteren">
             <a-row style="padding: 0 !important;">
              <a-col :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Nieuwe klanten</sdHeading>
                <a-row :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;">
                 <a-col :xl="16" :xs="24"
                        class="p-2 mb-2" style="vertical-align: middle;display: table-cell;align-self: center">
                  {{ globalSearchData.address.street }}
                  {{
                   globalSearchData.address.houseNumber
                  }} {{
                   globalSearchData.address.houseNumberAddition ? globalSearchData.address.houseNumberAddition : ''
                  }}<br/>
                  {{ globalSearchData.address.postalCode }} {{ globalSearchData.address.city }} <br/>Nederland
                 </a-col>
                 <a-col :xl="8" :xs="24" justify="end"
                        style="vertical-align: middle;align-self: center;text-align: end">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'CONSUMER')">
                   Doorgaan als particulier
                  </a-button>
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'BUSINESS')">
                   Doorgaan als zakelijk
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.relations.length > 0" justify="center">
              <a-col :sm="24" :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Bestaande klanten</sdHeading>
                <a-row v-for="relation in globalSearchData.relations" :key="relation.id" :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                 <a-col :xl="18" :xs="24" style="vertical-align: middle;display: table-cell;align-self: center;">
                                          <span><strong>{{ relation.fullName }}</strong>
                                                                                      <span class="status-badge ml-2">{{
                                                                                        relation.type && relation.type === 'BUSINESS' ? 'Zakelijk' : 'Particulier'
                                                                                       }}</span>
                                          </span><br/>
                  <span>{{ relation.street }} {{
                    relation.houseNumber
                   }} {{
                    relation.houseNumberAddition ? relation.houseNumberAddition : ''
                   }}<br/> {{ relation.postalCode }} {{ relation.city }}<br/> Nederland</span><br/>
                 </a-col>
                 <a-col :xl="6" :xs="24"
                        style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(relation,relation.type)"> Selecteren
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.coc.length > 0" ustify="center">
              <a-col :sm="24" :xs="24" class="mb-2">
               <sdHeading as="h6" class="mb-5" style="font-size:14px">Gevonden in<img
                :src="require(`@/static/img/kvk.svg`)"
                alt="" class="ml-5"/></sdHeading>
               <a-row v-for="coc in globalSearchData.coc" :key="coc.companyCocNumber" :gutter="30"
                      style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                <a-col :xl="18" :xs="24"
                       class="p-2" style="vertical-align: middle;display: table-cell;align-self: center">
                                          <span><strong>{{ coc.companyName }} - {{
                                            coc.companyCocNumber
                                           }}</strong></span><br/>
                 <span>{{ coc.street }} {{
                   coc.houseNumber
                  }} {{
                   coc.houseNumberAddition ? coc.houseNumberAddition : ''
                  }}<br/> {{ coc.postalCode }} {{ coc.city }}<br/>Nederland</span>
                </a-col>
                <a-col :xl="6" :xs="24" justify="end"
                       style="vertical-align: middle;align-self: center;text-align: end">
                 <a-button :disabled="loadingPreFillRelationOnSolarOrder"
                           :loading="loadingPreFillRelationOnSolarOrder"
                           class="mr-2 mb-2 mt-2 w-100"
                           size="large"
                           type="primary-outlined"
                           @click.prevent="handleSelectRelation(coc,'BUSINESS')"> Doorgaan als zakelijk
                 </a-button>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
           </a-col>
           <a-col :md="10">
            <sdCards style="text-align: center" title="Locatie">
             <img :src="globalSearchData.streetViewImageFileURL" style="width: 100%;height: auto;border-radius:5px">
            </sdCards>
           </a-col>
          </a-row>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <router-link to="/order/flow">
                 <sdButton class="btn-prev" to="" type="primary">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </router-link>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #product>
          <a-row :gutter="30">
           <a-col :xs="6">
            <sdCards title="Configurator">
             <a-row>
              <a-col :xs="24" class="mb-25">
               <div class="bzekyi ant-alert ant-alert-info ant-alert-with-description" data-show="true">
              <span aria-label="info-circle" class="anticon anticon-info-circle ant-alert-icon" role="img"><svg
               aria-hidden="true" class="" data-icon="info-circle" fill="currentColor" focusable="false" height="1em"
               viewBox="64 64 896 896" width="1em"><path
               d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path
               d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path></svg></span>
                <div class="ant-alert-content">
                 <div class="ant-alert-message"></div>
                 <div class="ant-alert-description">
                  De configurator werkt alleen bij producten zonder een vaste aantal panelen.
                 </div>
                </div>
               </div>
              </a-col>
             </a-row>
             <a-form layout="vertical" name="sDash_vertical-form">
              <a-form-item label="Elektriciteitsverbruik"
                           name="usageElectricity">
               <a-input :key="index"
                        v-model:value="order.usageElectricity"
                        addon-after="kWh per jaar"
                        name="usageElectricity"
                        @change="calculateAmountOfPanels"
               />
              </a-form-item>
              <a-form-item :rules="{validator:integerValidator}"
                           label="Aantal panelen"
                           name="panelCount"
              >
               <a-input v-model:value="order.panelCount" addon-after="panelen" placeholder="" step="1" type="text"
                        @change="fetchProduct"/>
              </a-form-item>
             </a-form>
            </sdCards>
           </a-col>
           <a-col :xs="18">
            <sdCards v-if="products.length < 1" headless>
             <a-empty description="Geef aantal panelen of "/>
            </sdCards>
            <a-row v-else :gutter="30">
             <a-col v-for="item in products" :key="item.id" :sm="8"
                    :xs="24" class="mb-30">
              <Suspense>
               <template #default>
                <ProductCardSolarSimple :data="item" @set-product-id="handleSelectProduct(item)"/>
               </template>
               <template #fallback>
                <sdCards headless>
                 <a-skeleton :paragraph="{ rows: 22 }" active/>
                </sdCards>
               </template>
              </Suspense>
             </a-col>
            </a-row>
           </a-col>
           <ActionWrapper>
            <div class="step-action-wrap">
             <div class="step-action-inner">
              <a-row>
               <a-col :xs="24">
                <div class="steps-action">
                 <sdButton class="btn-prev" type="primary" @click.prevent="() => prev()">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </div>
               </a-col>
              </a-row>
             </div>
            </div>
           </ActionWrapper>
          </a-row>
         </template>
         <template #relation>
          <Relation
           ref="relationComponentRef"
           :disable-is-business="true"
           :enable-birthday="false"
           :is-order-create="true"
           :maxAge="80"
           :minAge="18"
           :relation="order.relation"
           :show-card-save-button="false"
           :show-footer="false"
           :show-global-save-button="true"
           :showHeader="false"
           :showIBAN="false"
           type="ADD"
           @submit-external="handleRelationOnOrder($event)"
          ></Relation>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Vorige</span>
                </sdButton>
                <sdButton class="btn-next" type="primary"
                          @click.prevent="$refs.relationComponentRef.submitExternal()">
                 <span>Volgende</span>
                 <sdFeatherIcons size="16" type="arrow-right"/>
                </sdButton>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #summary>
          <Main>
           <a-row :gutter="25" type="flex">
            <a-col :md="10" :xs="24" style="display: flex;flex-flow: column;">
             <Suspense>
              <template #default>
               <ProductPriceSolarSimpleInfoCard :order="order" :priceSummary="true"/>
              </template>
              <template #fallback>
               <sdCards headless>
                <a-skeleton :paragraph="{ rows: 3 }" active avatar/>
               </sdCards>
              </template>
             </Suspense>
            </a-col>
            <a-col :md="14" :xs="24" style="display: flex;flex-flow: column;">
             <Suspense>
              <template #default>
               <RelationSVMSummaryCard :order="order"/>
              </template>
             </Suspense>
            </a-col>
           </a-row>
           <a-row :gutter="25">
            <a-col :xs="24">
             <sdCards title="Product vragen">
              <a-form ref="flowQuestionsForm" :model="{flowQuestions:order.flowQuestions}"
                      layout="vertical">
               <a-row :gutter="25">
                <a-col v-for="(item,index) in order.flowQuestions" :key="index" :md="12" :xs="24">
                 <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                                  :disabled-date="disabledStartDate"
                                  :format="dateFormat" class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()" :format="dateFormat"
                                  class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-input v-model:value="item.value" placeholder=""/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER' && item.type !== 'PANELCOUNT'"
                              :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER' && item.type === 'PANELCOUNT'"
                              :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" :disabled="true" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-select v-model:value="item.value" :allowClear="true" class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(option,index) in item.inputValues"
                                    :key="index"
                                    :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Ondertekenen">
              <a-row :gutter="25">
               <a-col :xs="12" class="signature align-center">
                <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'" :sigOption="sigState.option"
                               :w="'450px'"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                 <div role="alert">Uw handtekening is vereist</div>
                </div>
                <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                 <span>Opnieuw</span>
                </sdButton>
               </a-col>
               <a-col :xs="12" class="mb-5">
                <a-form ref="customerApprovalsForm" :model="order"
                        layout="vertical">
                 <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"
                              :name="['product','customerApprovalsValidation', index]"
                              :rules="{ validator: checkCheckBox }" class="checkbox-group"
                              label=""
                              style="padding-bottom:0px;margin-bottom:0px">
                  <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"
                              :name="['product','customerApprovalsValidation', index]">
                   {{ item }}
                  </a-checkbox>
                 </a-form-item>
                </a-form>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
          </Main>
          <ActionWrapper v-if="!isFinished">
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="light" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Terug</span>
                </sdButton>
                <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                          type="primary"
                          @click="handleCreateOrder"> Aanmaken
                </a-button>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateButtonWrapper,
 FigureWizards,
 OrderSummary,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {BasicFormWrapper, DatePickerWrapper, Main, ModalMain} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import FlowCards from "./component/FlowCards";
import {defaultOptions,} from "../../utility/enums";
import {ActionWrapper} from "../../components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "../../utility/validators";
import ConnectionTable from "./component/ConnectionTable";
import RelationSVMSummaryCard from "./component/RelationSVMSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRouter} from "vue-router";
import ProductCardSolarSimple from "@/view/orders/component/ProductCardSolarSimple";
import ProductSummaryInfoCard from "@/view/orders/component/ProductSummaryInfoCard";
import ProductPriceSolarSimpleInfoCard from "@/view/orders/component/ProductPriceSolarSimpleInfoCard";

const OrderCreate = {
 name: 'SolarSimple',
 components: {
  ConnectionTable,
  RelationSVMSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ConnectionCardWrapper,
  RelationCard,
  ActionWrapper,
  EstimateButtonWrapper,
  FlowCards,
  DatePickerWrapper,
  ProductCardSolarSimple,
  ProductSummaryInfoCard,
  ProductPriceSolarSimpleInfoCard,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 watch: {
  addressForm: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.addressForm.postalCode,
       houseNumber: this.addressForm.houseNumber,
       country: this.addressForm.country,
       type: 'SOLARSIMPLE',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const relationComponentRef = ref();
  const houseNumberAdditionsOptions = computed(() => state.globalSearch.houseNumberAdditions);
  const globalSearchData = computed(() => state.globalSearch.data);
  const globalSearchLoading = computed(() => state.globalSearch.loadingSearch);
  const disabledGlobalSearchButton = computed(() => state.globalSearch.disabledGlobalSearchButton);
  const products = reactive(computed(() => state.solarSimple.products));
  const loadingFetchSolarSimpleProducts = reactive(computed(() => state.solarSimple.loadingFetchSolarSimpleProducts));
  const order = reactive(computed(() => state.solarSimple.order));
  // watch(order, (data) => {
  //  if (data.flowQuestions.length > 0) {
  //   for (let i = 0; i < data.flowQuestions.length; i++) {
  //    if (data.flowQuestions[i].type === 'PANELCOUNT' && data.flowQuestions[i].value) {
  //     if (/^\d+$/.test(data.flowQuestions[i].value)) {
  //      dispatch('getSolarSimpleCalculation', {
  //       productID: order.value.product.id,
  //       panelCount: data.flowQuestions[i].value
  //      });
  //     } else {
  //      dispatch('getSolarSimpleCalculation', {
  //       productID: order.value.product.id,
  //       panelCount: 0
  //      });
  //     }
  //    }
  //   }
  //  }
  // }, {deep: true});
  const loadingCreateOrder = computed(() => state.solarSimple.loadingCreateOrder);
  const loadingPreFillRelationOnSolarSimpleOrder = computed(() => state.solarSimple.loadingPreFillRelationOnSolarSimpleOrder);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const customerApprovalsForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const selectedComponent = reactive({
   selectedComponent: null,
  });

  const rulesAddComponent = {
   selectedComponent: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'SOLARSIMPLE',
   });
  };

  const handleSelectRelation = async (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }
   await dispatch('preFillRelationOnSolarSimpleOrder', {value: relation, stepRoute: next});
  };

  const handleRelationOnOrder = (data) => {
   dispatch('addRelationToSolarSimpleOrder', {value: data, stepRoute: next})
  };

  const handleSelectProduct = (item) => {
   dispatch('setProductOnSolarSimpleOrder', {item: item, stepRoute: next})
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleCreateOrder = () => {
   const orderValidators = []
   signatureError.value = false;
   if (order.value.signatureType === 'SIGNATURE') {
    orderValidators.push(customerApprovalsForm.value.validate());
   }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureSolarSimpleData', signature1.value.save('image/svg+xml'))
      dispatch('createSolarSimpleOrder', {successRoute: orderCreateSuccessRoute})
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createSolarSimpleOrder', {successRoute: orderCreateSuccessRoute})
    }
   })
    .catch(() => dispatch('orderCreateValidationError'))
  };

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  let debounceProduct = null;
  const fetchProduct = () => {
   if (/^\d+$/.test(order.value.panelCount)) {
    clearTimeout(debounceProduct)
    debounceProduct = setTimeout(() => {
     dispatch('setElectricityUsageOnSolarSimpleOrder', Math.ceil((parseInt(order.value.panelCount) * 340) / 0.88));
     dispatch('fetchSolarProducts', {
      panelCount: order.value.panelCount
     });
    }, 500);
   }
  }

  let debounceCalculateAmountOfPanels = null;
  const calculateAmountOfPanels = () => {
   if (/^\d+$/.test(order.value.usageElectricity)) {
    clearTimeout(debounceCalculateAmountOfPanels)
    debounceCalculateAmountOfPanels = setTimeout(() => {
     const totalPiekWatt = parseInt(order.value.usageElectricity) * 0.88;
     const amountOfPanels = parseInt(totalPiekWatt) / 340;
     dispatch('setPanelCountOnSolarSimpleOrder', Math.ceil(amountOfPanels));
     dispatch('fetchSolarProducts', {
      panelCount: amountOfPanels
     });
    }, 500);
   }
  }


  onMounted(async () => {
   await dispatch('resetSolarSimpleOrder');
   await dispatch("cleanGlobalSearch");
   await dispatch('fetchSolarProducts', {panelCount: 0});
  });

  return {
   sigState,
   steps: [
    {
     title: "Adres",
     content: "address",
    },
    {
     title: "Product",
     content: "product",
    },
    {
     title: "Klantgegevens",
     content: "relation",
    },
    {
     title: "Overzicht",
     content: "summary",
    },
   ],
   clearSignature,
   signature1,
   addressForm,
   addressConnectionForm,
   houseNumberAdditionsOptions,
   addressFormRules,
   globalSearchData,
   order,
   globalSearchLoading,
   disabledGlobalSearchButton,
   relationComponentRef,
   defaultOptions,
   handleSelectRelation,
   globalSearch,
   done,
   prev,
   next,
   handleRelationOnOrder,
   handleCreateOrder,
   isFinished,
   current,
   onHandleChange,
   onShowSizeChange,
   pageSize,
   form,
   moment,
   loadingPreFillRelationOnSolarSimpleOrder,
   prevEan,
   customerApprovalsForm,
   checkCheckBox,
   signatureError,
   loadingCreateOrder,
   loadingHouseNumberAdditions,
   selectedComponent,
   rulesAddComponent,
   handleSelectProduct,
   products,
   loadingFetchSolarSimpleProducts,
   integerValidator,
   fetchProduct,
   calculateAmountOfPanels,
  };
 },
};

export default OrderCreate;
</script>
<style>
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}
</style>
