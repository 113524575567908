<template>
 <sdCards
  :bodyStyle="{
      backgroundColor: '#F8F9FB',
      borderRadius: '20px',
    }"
  headless
 >
  <OrderSummary>
   <sdHeading as="h4" class="summary-table-title"> Offerte Overzicht</sdHeading>
   <sdCards
    :bodyStyle="{
          backgroundColor: '#ffffff',
          borderRadius: '20px',
        }"
    headless
   >
    <div v-if="!order && (!calculationOrder && !priceSummary )"
         class="order-summary-inner">
     <a-skeleton active/>
    </div>
    <div v-else-if="order && !priceSummary && calculationOrder" class="order-summary-inner">
     <ul class="summary-list">
      <li>{{ order.productRevision.name }} pakket met {{ calculationOrder.panelCount }} panelen</li>
      <li style="padding: 0;margin:0">
       <span class="summary-list-title" style="color:#000000">Subtotaal :</span>
       <span class="summary-list-text">{{
         `${new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR'
         }).format(calculationOrder.packageEx)}`
        }}</span>
      </li>
      <li v-for="(item,index) in subProducts" :key="index" style="padding: 0;margin:0;color:#000000">
       <div class="summary-list-title">
        <span v-if="item.imageFileURL"><img :src="`${item.imageFileURL}`" alt="" style="max-width: 50px"/></span>
        <span class="ml-5" style="color:#000000"><small>- {{ item.name }}</small></span>
       </div>
      </li>
      <!--      <li>-->
      <!--       <span class="summary-list-title">Totaal incl. btw :</span>-->
      <!--       <span class="summary-list-text">-->
      <!--        {{-->
      <!--         `${new Intl.NumberFormat('nl-NL', {-->
      <!--          style: 'currency',-->
      <!--          currency: 'EUR'-->
      <!--         }).format(calculationOrder.packageIn)}`-->
      <!--        }}</span>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--       <span class="summary-list-title">Btw-teruggave :</span>-->
      <!--       <span class="summary-list-text">- {{-->
      <!--         `${new Intl.NumberFormat('nl-NL', {-->
      <!--          style: 'currency',-->
      <!--          currency: 'EUR'-->
      <!--         }).format(calculationOrder.packageVat)}`-->
      <!--        }}</span>-->
      <!--      </li>-->
      <!--      <li class="mt-2">-->
      <!--       <span class="summary-list-title" style="color:#000000">Forfait :</span>-->
      <!--       <span class="summary-list-text">-->
      <!--                {{-->
      <!--         `${new Intl.NumberFormat('nl-NL', {-->
      <!--          style: 'currency',-->
      <!--          currency: 'EUR'-->
      <!--         }).format(calculationOrder.forfait)}`-->
      <!--        }}</span>-->
      <!--      </li>-->
      <li>
       <hr style="width:100%">
      </li>
     </ul>
     <sdHeading as="h4" class="summary-total">
      <span class="summary-total-label">Totaal : </span>
      <span class="summary-total-amount">{{
        `${new Intl.NumberFormat('nl-NL', {
         style: 'currency',
         currency: 'EUR'
        }).format(calculationOrder.totalInvest)}`
       }}</span>
     </sdHeading>
    </div>
    <div v-else-if="order && priceSummary" class="order-summary-inner">
     <ul class="summary-list">
      <li>{{ order.product.name }} pakket met {{ Math.ceil(order.calculation.panelCount) }} panelen</li>
      <li>
       <span class="summary-list-title" style="color:#000000">Subtotaal :</span>
       <span class="summary-list-text">{{
         `${new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR'
         }).format(order.calculation.packageEx)}`
        }}</span>
      </li>
      <li v-for="(item,index) in order.subProducts" :key="index" style="padding: 0;margin:0;color:#000000">
       <div class="summary-list-title">
        <span v-if="item.imageFileURL"><img :src="`${item.imageFileURL}`" alt="" style="max-width: 50px"/></span>
        <span class="ml-5" style="color:#000000"><small>- {{ item.name }}</small></span>
       </div>
      </li>
      <!--      <li>-->
      <!--       <span class="summary-list-title">Totaal incl. btw :</span>-->
      <!--       <span class="summary-list-text">-->
      <!--        {{-->
      <!--         `${new Intl.NumberFormat('nl-NL', {-->
      <!--          style: 'currency',-->
      <!--          currency: 'EUR'-->
      <!--         }).format(order.calculation.packageIn)}`-->
      <!--        }}</span>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--       <span class="summary-list-title">Btw-teruggave :</span>-->
      <!--       <span class="summary-list-text">- {{-->
      <!--         `${new Intl.NumberFormat('nl-NL', {-->
      <!--          style: 'currency',-->
      <!--          currency: 'EUR'-->
      <!--         }).format(order.calculation.packageVat)}`-->
      <!--        }}</span>-->
      <!--      </li>-->
      <!--      <li class="mt-2">-->
      <!--       <span class="summary-list-title" style="color:#000000">Forfait :</span>-->
      <!--       <span class="summary-list-text">-->
      <!--                {{-->
      <!--         `${new Intl.NumberFormat('nl-NL', {-->
      <!--          style: 'currency',-->
      <!--          currency: 'EUR'-->
      <!--         }).format(order.calculation.forfait)}`-->
      <!--        }}</span>-->
      <!--      </li>-->
      <li>
       <hr style="width:100%">
      </li>
     </ul>
     <sdHeading as="h4" class="summary-total">
      <span class="summary-total-label">Totaal: </span>
      <span class="summary-total-amount">{{
        `${new Intl.NumberFormat('nl-NL', {
         style: 'currency',
         currency: 'EUR'
        }).format(order.calculation.totalInvest)}`
       }}</span>
     </sdHeading>
    </div>
   </sdCards>
  </OrderSummary>
 </sdCards>
</template>
<script>
import {computed, defineComponent} from 'vue';
import VueTypes from "vue-types";
import {OrderSummary} from "@/view/orders/component/style";
import {useStore} from "vuex";

const ProductPriceSolarSimpleInfoCard = defineComponent({
 name: 'ProductPriceSolarSimpleInfoCard',
 components: {
  OrderSummary,
 },
 props: {
  order: VueTypes.object.def(null),
  priceSummary: VueTypes.bool.def(false),
 },
 setup(props) {
  const {state, dispatch} = useStore();
  const calculation = computed(() => {
   return state.SolarSimpleCalculation.calculation
  });
  const calculationOrder = computed(() => {
   if (props.order && props.order.productRevision) {
    dispatch('getSolarSubProductsForOrder', props.order.id);
   }

   return state.SolarSimpleCalculation.calculationOrder
  });
  const subProducts = computed(() => {
   // if (props.summary) {
   //  return props.order.product.solarSubProducts
   // }

   return state.solarSubProduct.subProductsForOrder;
  });

  return {
   calculation,
   calculationOrder,
   subProducts,
  };
 },
});

export default ProductPriceSolarSimpleInfoCard;
</script>
