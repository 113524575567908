<template>
 <div v-if="isLoader" class="spin">
  <a-spin/>
 </div>
 <ProductCard v-else style="margin-bottom: 30px; height:100%">
  <sdModal :onCancel="handlePriceModalCancel" :visible="priceModalVisible" title="" type="primary">
   <ModalMain>
    <ProductPriceSolarSimpleInfoCard :order="data" :priceSummary="true"/>
   </ModalMain>
  </sdModal>
  <figure style="text-align: center">
   <img
    v-if="data && data.product && data.product.imageFileURL"
    :src="data.product.imageFileURL"
    alt=""
    style="padding:20px; max-width: 250px"
   />
   <img v-else
        :src="require('@/static/img/avatar/profileImage.png')"
        alt=""
   />
  </figure>
  <figcaption>
   <sdHeading as="h5" class="product-single-title" style="text-align: center;margin-bottom: 10px">
    {{ data.product.name }}
   </sdHeading>
   <sdHeading as="h6" class="product-single-title" style="text-align: center;margin-bottom: 30px;font-size:14px">
    <InfoCircleOutlined
     style="font-size: 16px;padding-right:5px"
     @click.prevent="showPriceModal"/>
    <span
     style="font-size: 0.9rem;">{{
      `${new Intl.NumberFormat('nl-NL', {
       style: 'currency',
       currency: 'EUR'
      }).format(data.calculation.totalInvest)}`
     }}</span>

   </sdHeading>
   <div class="product-single-action"
        style="position: absolute;right: 0;bottom: 0;padding: 0;margin: 0;display:flex;width:100%">
    <sdButton class="btn-cart" size="small" style="width:100% !important;" type="primary"
              @click="handleSelectProduct">
     <sdFeatherIcons size="14" type="shopping-bag"/>
     <span>Selecteren</span>
    </sdButton>
   </div>
  </figcaption>
 </ProductCard>
</template>
<script>
import {ProductCard} from '../Style';
import PropTypes from 'vue-types';
import VueTypes from 'vue-types';
import {computed, defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {InfoCircleOutlined} from "@ant-design/icons-vue";
import ProductPriceSolarSimpleInfoCard from "@/view/orders/component/ProductPriceSolarSimpleInfoCard";

const ProductCards = defineComponent({
 name: 'ProductCardSolarSimpel',
 components: {ProductPriceSolarSimpleInfoCard, ProductCard, InfoCircleOutlined},
 props: {
  data: PropTypes.object,
  type: VueTypes.oneOf(['CONSUMER', 'BUSINESS']).def('CONSUMER'),
 },
 methods: {
  handleSelectProduct() {
   this.$emit('set-product-id')
  },
 },
 setup() {
  const {dispatch, state} = useStore();
  const isLoader = computed(() => state.order.loadingOrdersOfferFlowProductCalculations);
  const {matched} = useRoute();

  const priceModalVisible = ref(false)
  const showPriceModal = () => {
   priceModalVisible.value = true;
  }

  const handlePriceModalCancel = async () => {
   priceModalVisible.value = false;
  }

  return {
   dispatch,
   isLoader,
   matched,
   handlePriceModalCancel,
   priceModalVisible,
   showPriceModal,
  };
 },
});

export default ProductCards;
</script>
